import React from "react";
import {DefaultLayout} from "../organisms/DefaultLayout";
import * as styles from "../styles/careers/404.module.scss"
import {Link} from "gatsby";
import { SEO } from "../atoms/seo/SEO"
import seoImage from "../images/vision/vision-osaka.jpg";

export default ({location: pathname}) =>
    <DefaultLayout>
      <SEO pathname={pathname} title="代表メッセージ | CEO Message" description="Suprieve Holdings株式会社は、世界中どこにいても受けられる教育や、年間販売数56万個、Amazonコスメストア売上日本3位のWEBサイトを運用する「企画開発事業」、日本随一の採用力と正社員500名超を基盤に販売・営業代行を展開している「採用事業」など、『この上ない最高の会社を創る』という企業理念のもと 世界、顧客、従業員にこの上ない最高を提供する会社を目指しています。" image={seoImage} />

      <div className={styles.thanks}>
        <h2>お問い合わせ
          送信完了</h2>
        <p>お問い合わせ頂き誠にありがとうございました。<br/>
          内容を確認させていただき、後ほど担当者よりご回答をさせていただきます。<br/>
          今しばらくお待ちいただけますよう、宜しくお願い申し上げます。
        </p>
        <div className={styles.button}>
          <Link to='/'>Top Page</Link>
        </div>
      </div>

    </DefaultLayout>;