import React, { ReactNode } from "react";
import "../styles/style.scss";
import Footer from "./core/Footer";
import * as styles from "./DefaultLayout.module.scss";
import { Layout } from "./Layout";
import { Header } from "./core/Header";
import { FooterSp } from "./core/FooterSp";

interface ICompanyLayout {
  children: ReactNode;
  extraHeader?: ReactNode;
  className?: string;
  footer?: boolean;
  isWhite?: boolean;
  isHistory?: boolean;
  isWhiteBackground?: boolean;
}
export const DefaultLayout = ({
  children,
  extraHeader,
  className,
  footer = true,
  isWhite = false,
  isHistory = false,
  isWhiteBackground = false,
}: ICompanyLayout) => (
  <Layout>
    <div className={[styles.container, className].join(" ")}>
      <Header
        isWhite={isWhite}
        isHistory={isHistory}
        isWhiteBackground={isWhiteBackground}
      >
        {extraHeader}
      </Header>
      {children}
      {footer && <Footer />}
      {footer && <FooterSp />}
    </div>
  </Layout>
);
